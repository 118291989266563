<template>
  <article class="content"
  >
    <div class="row" style="background: linear-gradient(#14191c, #4aab89, #14191c, #4aab89);

">
      <section class="text-center col-lg-8 col-sm-12"

      >


        <HeadImage :image="mainImage" clamp align="text-center" text-transform="uppercase" :color="thirdColor"
                   caption="Experience Tulum At Its Fullest" title="Tulum Concierge Services"></HeadImage>
        <h2
          class=" text-center"
          style="
            text-transform: uppercase;
            font-size: 0.7rem;

            letter-spacing: 0.2rem;
          "
        >
        </h2>

        <div class="card text-center" style="display: inline-block;padding: 20px; ">


          <p>
            We provide comprehensive <strong>concierge services</strong> for individuals and groups coming to
            <b>Tulum</b>. Our services encompass coordinating various aspects of your visit, our expertise is around
            special dinners,
            events and parties,
            including Top
            <router-link :to="{name: 'BeachClubs'}">Beach Clubs</router-link>
            ,

            <router-link :to="{name: 'Clubs'}">Nightclubs</router-link>
            , and
            <router-link :to="{path: '/nye'}">Festivals</router-link>
          </p>


          <br>
          <br>


          <p class="font-weight-900">tulum.party</p>

          <p>
            We craft exceptional experiences, ensuring you enjoy your time in Tulum to the fullest.
          </p>
          <br>
          <small style="margin-top: -30px">NO EXTRA FEE</small>
        </div>
        <card
          class="card text-center col-md-12 "
          style="z-index: 1; margin: 2px;"
        >
          <img v-lazy="runningAstronaut+'?w=120&h=120&fit=clamp'" height="120" width="120">

          <div class="card centered"
               style="padding: 10px;"
               :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"
          >

            <div :style="{backgroundColor: this.thirdColor}" style="padding: 20px">

              <img v-lazy="qrImg+'?w=300&h=300'" width="300px" height="300px" alt="concierge services qr">


            </div>
            <br />
            <h4 class="tulum-party-title-alt" style="font-size: 1.8rem">
              Your planning simplified.

            </h4>


            <p>GET STARTED</p>
            <br>
            <RsvpViaDialog
              :club-config="this.rsvpConfig"
              mailAddress="gaby@tulum.party"
              message="CONCIERGE_INFO:%20%0a-------%0a"
              text="TALK TO US"
              footer="NO EXTRA FEE"
              legend="Settle In, Chill Out, We've Got Your Plans Covered."

            ></RsvpViaDialog>

            <br>
            <a href="mailto:gaby@tulum.party">gaby@tulum.party</a>
            <br />
            <small
            >We operate from 9AM - 11PM / Answer within 15 mins</small
            >
            <br>


          </div>

          <div class="card">

            <MiniCarrousel folder="concierge-services" :num-photos="3"></MiniCarrousel>

            <h4 class="alt-font" style="text-transform: uppercase">Enjoy Your Trip.</h4>


            <p>Our service allows you to <strong>focus on your trip</strong> while we handle the logistical details. We
              operate on a
              transparent model with <strong>no service fees</strong></p>
          </div>


          <!--          <div class="">-->

          <!--            <h3 class="section-header">TULUM</h3>-->
          <!--          <Carrousel folder="concierge-services" ></Carrousel>-->
          <!--          </div>-->
          <section class="row text-left">
            <div class="card" style="padding:20px"
                 :style="{backgroundColor: secondaryColor}"
            >

              <h4 class="section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  :alt="this.title"
                />
                Couples
              </h4>


              <ResponsiveImage
                title="Couples Packages"
                clamp
                image="https://imgix.cosmicjs.com/94779fb0-04fd-11f0-9c94-c1d9b01cc071-concierge-services-14.jpg"></ResponsiveImage>
              <hr>
              <p>For couples looking for a getaway that <strong>blends romance, vibrant energy, and world-class
                entertainment</strong>,
                we curate experiences that <i>spark connection and create unforgettable moments.</i></p>
              <ul>

                <li><strong>Intimate Romantic Dinners:</strong> Exquisite cuisine in breathtaking settings,
                  creating the perfect backdrop for romance.
                </li>
                <li><strong>Magical Engagement Ring Proposals:</strong> Let us orchestrate a proposal as unique as your
                  love story, in the most enchanting Tulum locations.
                </li>
                <li><strong>Vibrant Nights Out:</strong> Experience Tulum's nightlife, dancing together under the
                  stars and creating unforgettable memories.
                </li>
              </ul>
            </div>

          </section>
          <section class="row text-left">
            <div class="card" style="padding:20px"
                 :style="{backgroundColor: secondaryColor}"
            >

              <h4 class="section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  :alt="this.title"
                />
                Solo Travelers
              </h4>

              <hr
                style="height: 2px"
                :style="{ backgroundColor: this.primaryColor }"
              />
              <ResponsiveImage
                title="Tulum Solo Traveler"
                clamp
                image="https://imgix.cosmicjs.com/21b77000-06e6-11f0-993b-3bd041905fff-keine.jpg"></ResponsiveImage>
              <br>

              <p>
                <strong>Traveling solo?</strong> Embrace the adventure. Discover your community and the vibrant energy
                of Tulum. You'll
                never be alone on the dance floor
              </p>
              <ul>
                <li><strong>Curated Solo Experiences:</strong> Discover hidden gems and local hotspots tailored to your
                  interests.
                </li>
                <li><strong>Vibrant Nightlife Connections:</strong> Join fellow travelers for unforgettable nights out,
                  dancing under the stars.
                </li>
                <li><strong>Personalized Itineraries:</strong> Craft your perfect adventure with custom itineraries
                  based on your pace and preferences.
                </li>

              </ul>
            </div>

          </section>

          <section class="row text-left">
            <div class="card" style="padding:20px"
                 :style="{backgroundColor: secondaryColor}"
            >
              <h4 class="section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  :alt="this.title"
                />
                Friends Gathering
              </h4>

              <ResponsiveImage
                title="Celebrate With Your Friends In Tulum"
                clamp
                image="https://imgix.cosmicjs.com/94e6dc40-04fd-11f0-9c94-c1d9b01cc071-concierge-services-10.jpg"></ResponsiveImage>

              <hr
                style="height: 2px"
                :style="{ backgroundColor: this.primaryColor }"
              />

              <p><strong>Planning a legendary getaway for your crew?</strong> We specialize in curating unforgettable
                experiences, from
                wild <strong>bachelor/bachelorette </strong>bashes to laid-back reunions. Let us orchestrate the perfect
                celebration, so
                you can focus on creating those priceless memories.</p>
              <ul>
                <li><strong>Epic Bachelor & Bachelorette Parties:</strong> We design bespoke celebrations, tailored to
                  your crew's vibe, ensuring a legendary send-off.
                </li>
                <li><strong>VIP Venue Access & Table Reservations:</strong> Secure prime spots and exclusive tables at
                  the hottest venues.
                </li>
                <li><strong>Seamless Group Transportation:</strong> Travel together with ease and style, eliminating the
                  stress of logistics.
                </li>
                <li><strong>Personalized Group Itineraries:</strong> Maximize your time with custom itineraries, packed
                  with activities you'll all love.
                </li>
              </ul>


              <p>

                We facilitate planning for special events such as bachelor and bachelorette parties, ensuring an
                unforgettable experience.
              </p>
            </div>

          </section>
          <section class="row text-left">
            <div class="card" style="padding:20px"
                 :style="{backgroundColor: secondaryColor}"
            >

              <h4 class="section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  :alt="this.title"
                />
                Festival Travelers
              </h4>

              <hr
                style="height: 2px"
                :style="{ backgroundColor: this.primaryColor }"
              />
              <ResponsiveImage
                title="Festival Travelers."
                clamp
                image="https://imgix.cosmicjs.com/aa6d39d0-0dfc-11f0-8617-41c4c9731576-festival-travelers.png
"></ResponsiveImage>

              <br>
              <p>
                <router-link :to="{name: 'TulumNye2025'}">Festival bound?</router-link>
                We've got you covered: Tickets, VIP tables, special accommodations & backstage access.
                Your ultimate Tulum festival trip, simplified
              </p>
              <br>
              <ul>
                <li><strong>Tickets and VIP Access:</strong> Secure your entry to the festival and enjoy premium views
                  with VIP table reservations.
                </li>
                <li><strong>Accommodation Coordination:</strong> Find the perfect place to stay near the action,
                  tailored to your preferences.
                </li>
                <li><strong>Transportation Services:</strong> Travel to and from the festival and events with ease,
                  thanks to our reliable transportation options.
                </li>
                <li><strong>Personalized Itinerary Planning:</strong> Craft a custom itinerary to make the most of your
                  festival experience.
                </li>
                <li><strong>Local Expert Recommendations:</strong> Discover the area's hidden gems with insider tips
                  from our local experts.
                </li>
                <li><strong>Backstage Access (If Available):</strong> Get closer to the artists with exclusive backstage
                  access (subject to availability).
                </li>
              </ul>

            </div>
          </section>

          <div class="card text-left" style="padding:20px"
               :style="{backgroundColor: secondaryColor}"
          >
            <h4>We got you covered</h4>

            <ResponsiveImage
              image="https://imgix.cosmicjs.com/7c63e950-3e6e-11ec-9828-0f2f00f103e0-image.png"></ResponsiveImage>

            <hr>

            <p>
              Our years of Tulum experience, combined with a strong partner network and timely support, establish us as
              the trusted expert for all your Tulum entertainment needs.

            </p>
            <h4>Our Services</h4>
            <ul>
              <li>
                Personal Support During Planning & Execution.
              </li>
              <li>
                Support All The Way Through
              </li>
              <li>
                Unbiased Recommendations. We Align Your Preferences.
              </li>
              <li>
                Reservation Management for all
                <router-link :to="{name: 'Clubs'}">Tulum nightclubs</router-link>
                and beach clubs.
              </li>
              <li>
                Special Occasions: Bachelorette & Bachelor Party Planning
              </li>
              <li>
                Festival ticket procurement and VIP access arrangements.
              </li>
              <li>
                Table reservations, Dinners, Beach Clubs, VIP Spots, and Bottle Service.
              </li>
            </ul>
          </div>


          <section :style="{ background: `linear-gradient(${this.primaryColor}, black )` }" class="text-left">


            <div class="card text-left" style="padding:20px"
                 :style="{backgroundColor: primaryColor}"
            >

              <h4 class="section-header">
                <i class="tim-icons icon-map-big"></i>
                Frequent Asked Questions /.
              </h4>
              <dl class="text-left" v-for="faq in this.faqs" style="padding: 0 20px 1px 20px">
                <dt style="margin-top: 1px"><strong>Q: {{ faq.title }}</strong></dt>
                <dd style="margin-bottom: 1px;line-height: 1.6" v-html="faq.content">

                </dd>
              </dl>

            </div>

          </section>

          <br />
          <section class="text-center">
            <div class="card text-center" style="padding:20px"
                 :style="{backgroundColor: primaryColor}"
            >

              <ResponsiveImage

                image="https://imgix.cosmicjs.com/63e47180-0e7a-11f0-91ec-af6adca2ead2-91bfbe772a3feaf78226aca8782b6e79.webp"></ResponsiveImage>
              <h4 class="section-header">

                REVIEWS
              </h4>
              <p>
                At tulum.party, we pride ourselves on providing exceptional service.
              </p>
              <div class="card text-center" style="padding: 20px">

                <hr>
                <p><b>tulum party</b> is amazing. They are willing to curate a good time based on what you need. Tulum
                  is
                  lucky to have them!</p>
                <br>
                <p><strong>tulum.party</strong> helped us plan our entire tulum trip, answered any questions, and even
                  helped us with our budget!!! Can’t thank them enough</p>
                <br>
                <p><strong>tulum.party</strong> es lo mejor chicos Book anything gig with them !</p>
                <br>
                <div class="star-container centered">
                  <svg height="30" width="30" class="an">
                    <polygon class="star" points="15, 3 18, 11 26, 11 20, 16 23, 24 15, 19 7, 24 10, 16 4, 11 12, 11" />
                  </svg>
                  <svg height="30" width="30">
                    <polygon class="star" points="15, 3 18, 11 26, 11 20, 16 23, 24 15, 19 7, 24 10, 16 4, 11 12, 11" />
                  </svg>
                  <svg height="30" width="30">
                    <polygon class="star" points="15, 3 18, 11 26, 11 20, 16 23, 24 15, 19 7, 24 10, 16 4, 11 12, 11" />
                  </svg>
                  <svg height="30" width="30">
                    <polygon class="star" points="15, 3 18, 11 26, 11 20, 16 23, 24 15, 19 7, 24 10, 16 4, 11 12, 11" />
                  </svg>
                  <svg height="30" width="30">
                    <polygon class="star" points="15, 3 18, 11 26, 11 20, 16 23, 24 15, 19 7, 24 10, 16 4, 11 12, 11" />
                  </svg>
                </div>
              </div>

            </div>
          </section>

        </card>
        <br />
      </section>

      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <br>
        <h4 class="section-header text-center" style="font-weight: bold;font-size:0.7rem;text-transform: lowercase">
          <img v-lazy="this.runningAstronaut" height="90px" width="83x">

        </h4>
        <div class="row">
          <div class="col-sm-12 text-center">
            <p>
              Sit Back & Relax. <br>Let's us handle all your plans
            </p>
            <hr>
          </div>
          <div class="col-sm-12 text-center">
            <RsvpViaDialog
              :club-config="this.rsvpConfig"
              mailAddress="gaby@tulum.party"
              message="CONCIERGE_INFO:%20%0a-------%0a"
              text="TALK TO US"
              footer="NO EXTRA FEE"
              legend="Settle In, Chill Out, We've Got Your Plans Covered."

            ></RsvpViaDialog>
            <hr>
          </div>
        </div>
        <br>

        <section :style="{ background: `linear-gradient(${this.primaryColor}, ${this.thirdColor} )` }">

          <ResponsiveImage

            hl="170"
            wl="300"
            clamp
            image="https://imgix.cosmicjs.com/952ebab0-04fd-11f0-9c94-c1d9b01cc071-concierge-services-20.jpg"></ResponsiveImage>
          <h4 class="section-header alt-font">
            <img v-lazy="serviceIcon" height="30px" width="30px" alt="our services"
                 style="border: 1px solid #be6a4d;border-radius: 90px;padding: 2px"
            >
            SERVICES
          </h4>
          <hr style="height: 2px" :style="{ backgroundColor: primaryColor }" />
          <ul>
            <li>
              Personal Support During Planning & Execution.
            </li>
            <li>
              Support All The Way Through
            </li>
            <li>
              Unbiased Recommendations. We Align Your Preferences.
            </li>
            <li>
              Reservation Management for all
              <router-link :to="{name: 'Clubs'}">Tulum nightclubs</router-link>
              and beach clubs.
            </li>
            <li>
              Special Occasions: Bachelorette & Bachelor Party Planning
            </li>
            <li>
              Festival ticket procurement and VIP access arrangements.
            </li>
            <li>
              Table reservations, Dinners, Beach Clubs, VIP Spots, and Bottle Service.
            </li>
          </ul>

        </section>
        <section :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">

          <ResponsiveImage

            hl="170"
            wl="300"
            clamp
            image="https://imgix.cosmicjs.com/9551ac00-04fd-11f0-9c94-c1d9b01cc071-concierge-services-17.jpg"></ResponsiveImage>
          <h4 class="section-header alt-font">
            <i class="tim-icons icon-map-big"
               style="border: 1px solid #be6a4d;border-radius: 90px;padding: 2px"

            ></i>
            BENEFITS
          </h4>
          <hr style="height: 2px" :style="{ backgroundColor: primaryColor }" />

          <ul>
            <li>Insider access to Tulum's hottest events.</li>
            <li>Reduced planning time and effort.</li>
            <li>VIP spots and bottle service at exclusive venues.</li>
            <li>Personalized recommendations from local experts. We know Tulum's party scene inside and out
            </li>
            <li>Personalized service based on your preferences.</li>
            <li>Consistent communication and follow-up.</li>
            <li>Single point of contact for all venues and events.</li>
            <li>Direct communication with your personal concierge.</li>
            <li>Service provided without additional fees.</li>
          </ul>

        </section>

        <SmartMenu :links="links"
                   :primary-color="this.primaryColor"
                   :secondary-color="this.secondaryColor"
                   :with-rsvp="false"
                   :logo="this.astroImg">

        </SmartMenu>
      </aside>

    </div>

  </article>
</template>

<script>
import { mapState } from 'vuex';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );

import Carrousel from '@/components/Carrousel';
import HeadImage from '@/components/HeadImage/HeadImage';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import MiniCarrousel from '@/components/MiniCarrousel';
import SmartMenu from '@/components/SmartMenu/SmartMenu';

export default {
  name: 'ConciergeServices',
  props: ['slug'],
  created() {
    this.$store.dispatch('getLinks', { slug: 'tulum-concierge-services' });
    this.$store.dispatch('getFaqsByCategory', 'tulum-concierge-services');
  },
  metaInfo() {

    return {
      title: `Tulum Concierge Services | tulum.party`,
      meta: [
        {
          name: 'description',
          content: `tulum.party: Your single point of contact for Tulum's best events! VIP access, reservations & expert local guidance. No fees. Information & Reservations for all Clubs & Events. Plan with us!`
        },
        {
          name: 'keywords',
          content: `tulum concierge services reservations recommendations parties`
        },
        {
          property: 'og:title',
          content: `Tulum Concierge Services | tulum.party`
        },
        {
          property: 'og:url',
          content: `https://tulum.party/tulum-concierge-services`
        },
        {
          property: 'og:description',
          content: `tulum.party: Your single point of contact for Tulum's best events! VIP access, reservations & expert local guidance. No fees. Information & Reservations for all Clubs & Events. Plan with us!`
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: this.main_image
        }
      ]
    };
  },
  data: () => {
    return {
      showAll: false,
      logo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      pinaColada: 'https://imgix.cosmicjs.com/58eaefd0-f862-11ef-97be-337de38c2241-9cf2a6e55f744ad28682f5f01d02e18c.png',
      mainImage: 'https://imgix.cosmicjs.com/33233bd0-ebc0-11ef-a238-c1ad09f4b1ad-orange-dj.png',
      palm: 'https://imgix.cosmicjs.com/638ea2a0-0e75-11f0-91ec-af6adca2ead2-9b2f6ac46c45bd6643d414c86c1c44a4.webp',
      runningAstronaut: 'https://imgix.cosmicjs.com/c6bf6620-f345-11ef-b4ad-8b78ecd420c2-running-orange-astro.png',
      image1: 'https://imgix.cosmicjs.com/f178c960-04fc-11f0-9c94-c1d9b01cc071-concierge-services-7.jpg',
      image2: 'https://imgix.cosmicjs.com/945a53b0-04fd-11f0-9c94-c1d9b01cc071-concierge-services-13.jpg',
      image3: 'https://imgix.cosmicjs.com/9551ac00-04fd-11f0-9c94-c1d9b01cc071-concierge-services-17.jpg',
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      showDetailsTab: true,
      showFaqsTab: false,
      showLocationTab: false,
      showReviewTab: false,
      video: false,
      primaryColor: '#2d2b2b',
      secondaryColor: '#1f1f1f',
      thirdColor: '#be6a4d',

      images: ['https://image.lexica.art/full_webp/9e6db2e5-56dd-493b-a80e-9e9f3555dedf',
        'https://imgix.cosmicjs.com/82856090-f18b-11ec-8fb8-5d396858ac9b-photo2022-06-2112-46-08.jpg?h=430',
        'https://imgix.cosmicjs.com/491a08e0-b45b-11ed-bce9-6ddb530a836d-Bonbonniere-Tulum.jpg?w=580&h=350&fit=clamp&auto=format'

      ],
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      qrImg: 'https://imgix.cosmicjs.com/4008adc0-ec89-11ef-a238-c1ad09f4b1ad-concierge-qr.png',
      showPalms: false,
      clubStyle: '',
      clubConfig: {},
      rsvpConfig: {
        name: 'Concierge Services',
        color: '#be6a4d',
        primaryColor: '#1f1f1f',
        secondaryColor: '#be6a4d',
        logo: 'https://imgix.cosmicjs.com/16bea710-3e94-11ef-a504-63e081e4680f-small-drink.png'
      },
      clubTagsConfig: {},
      serviceIcon: 'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',
      main_image:
        'https://imgix.cosmicjs.com/f67523e0-c705-11ee-9ce5-59949019255e-djs.jpg'

    };
  },
  methods: {},
  components: {
    SmartMenu,
    MiniCarrousel,
    ResponsiveImage,
    HeadImage,
    Carrousel,
    RsvpViaDialog
  },
  computed: mapState({
    incomingClubParties: (state) => {
      return state.incomingClubParties[state.venue.slug] || [];
    },
    faqs: (state) => {
      return state.faqs_category || [];
    },
    club: (state) => state.venue,
    clubs: (state) => state.venues,
    links: (state) => state.links
  })
};
</script>
